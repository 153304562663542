import stock_trading1 from '../../../../../../../../../Assets/images/projects_pics/ruby_rails/stock_trading_app/stock_trading1.jpg'
import stock_trading2 from '../../../../../../../../../Assets/images/projects_pics/ruby_rails/stock_trading_app/stock_trading2.jpg'
import stock_trading3 from '../../../../../../../../../Assets/images/projects_pics/ruby_rails/stock_trading_app/stock_trading3.jpg'
import stock_trading4 from '../../../../../../../../../Assets/images/projects_pics/ruby_rails/stock_trading_app/stock_trading4.jpg'
import stock_trading5 from '../../../../../../../../../Assets/images/projects_pics/ruby_rails/stock_trading_app/stock_trading5.jpg'
import stock_trading6 from '../../../../../../../../../Assets/images/projects_pics/ruby_rails/stock_trading_app/stock_trading6.jpg'
import stock_trading7 from '../../../../../../../../../Assets/images/projects_pics/ruby_rails/stock_trading_app/stock_trading7.jpg'
import stock_trading8 from '../../../../../../../../../Assets/images/projects_pics/ruby_rails/stock_trading_app/stock_trading8.jpg'
import stock_trading9 from '../../../../../../../../../Assets/images/projects_pics/ruby_rails/stock_trading_app/stock_trading9.jpg'
import stock_trading10 from '../../../../../../../../../Assets/images/projects_pics/ruby_rails/stock_trading_app/stock_trading10.jpg'
import stock_trading11 from '../../../../../../../../../Assets/images/projects_pics/ruby_rails/stock_trading_app/stock_trading11.jpg'
import stock_trading12 from '../../../../../../../../../Assets/images/projects_pics/ruby_rails/stock_trading_app/stock_trading12.jpg'
import stock_trading13 from '../../../../../../../../../Assets/images/projects_pics/ruby_rails/stock_trading_app/stock_trading13.jpg'
import stock_trading14 from '../../../../../../../../../Assets/images/projects_pics/ruby_rails/stock_trading_app/stock_trading14.jpg'
import stock_trading15 from '../../../../../../../../../Assets/images/projects_pics/ruby_rails/stock_trading_app/stock_trading15.jpg'
import stock_trading16 from '../../../../../../../../../Assets/images/projects_pics/ruby_rails/stock_trading_app/stock_trading16.jpg'
import stock_trading17 from '../../../../../../../../../Assets/images/projects_pics/ruby_rails/stock_trading_app/stock_trading17.jpg'
import stock_trading18 from '../../../../../../../../../Assets/images/projects_pics/ruby_rails/stock_trading_app/stock_trading18.jpg'
import stock_trading19 from '../../../../../../../../../Assets/images/projects_pics/ruby_rails/stock_trading_app/stock_trading19.jpg'
import stock_trading20 from '../../../../../../../../../Assets/images/projects_pics/ruby_rails/stock_trading_app/stock_trading20.jpg'
import stock_trading21 from '../../../../../../../../../Assets/images/projects_pics/ruby_rails/stock_trading_app/stock_trading21.jpg'
import stock_trading22 from '../../../../../../../../../Assets/images/projects_pics/ruby_rails/stock_trading_app/stock_trading22.jpg'
import stock_trading23 from '../../../../../../../../../Assets/images/projects_pics/ruby_rails/stock_trading_app/stock_trading23.jpg'
import stock_trading24 from '../../../../../../../../../Assets/images/projects_pics/ruby_rails/stock_trading_app/stock_trading24.jpg'
import stock_trading25 from '../../../../../../../../../Assets/images/projects_pics/ruby_rails/stock_trading_app/stock_trading25.jpg'
import stock_trading26 from '../../../../../../../../../Assets/images/projects_pics/ruby_rails/stock_trading_app/stock_trading26.jpg'
import stock_trading27 from '../../../../../../../../../Assets/images/projects_pics/ruby_rails/stock_trading_app/stock_trading27.jpg'
import stock_trading28 from '../../../../../../../../../Assets/images/projects_pics/ruby_rails/stock_trading_app/stock_trading28.jpg'
import stock_trading29 from '../../../../../../../../../Assets/images/projects_pics/ruby_rails/stock_trading_app/stock_trading29.jpg'
import stock_trading30 from '../../../../../../../../../Assets/images/projects_pics/ruby_rails/stock_trading_app/stock_trading30.jpg'
import stock_trading31 from '../../../../../../../../../Assets/images/projects_pics/ruby_rails/stock_trading_app/stock_trading31.jpg'
import stock_trading32 from '../../../../../../../../../Assets/images/projects_pics/ruby_rails/stock_trading_app/stock_trading32.jpg'

const Stock_trading_app = [
    stock_trading1,
    stock_trading2,
    stock_trading3,
    stock_trading4,
    stock_trading5,
    stock_trading6,
    stock_trading7,
    stock_trading8,
    stock_trading9,
    stock_trading10,
    stock_trading11,
    stock_trading12,
    stock_trading13,
    stock_trading14,
    stock_trading15,
    stock_trading16,
    stock_trading17,
    stock_trading18,
    stock_trading19,
    stock_trading20,
    stock_trading21,
    stock_trading22,
    stock_trading23,
    stock_trading24,
    stock_trading25,
    stock_trading26,
    stock_trading27,
    stock_trading28,
    stock_trading29,
    stock_trading30,
    stock_trading31,
    stock_trading32
]
  
  export default Stock_trading_app
  