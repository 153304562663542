import momentum_app from '../../../../../../../../../Assets/images/projects_pics/react_js/momentum_app/Momentum_app.jpg'
import momentum_app1 from '../../../../../../../../../Assets/images/projects_pics/react_js/momentum_app/Momentum_app1.jpg'
import momentum_app2 from '../../../../../../../../../Assets/images/projects_pics/react_js/momentum_app/Momentum_app2.jpg'
import momentum_app3 from '../../../../../../../../../Assets/images/projects_pics/react_js/momentum_app/Momentum_app3.jpg'
import momentum_app4 from '../../../../../../../../../Assets/images/projects_pics/react_js/momentum_app/Momentum_app4.jpg'


const Momentum_app = [
        momentum_app,
        momentum_app1,
        momentum_app2,
        momentum_app3,
        momentum_app4,
    ]


export default Momentum_app