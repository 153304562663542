import jollibee_lp from '../../../../../../../../../Assets/images/projects_pics/react_js/jollibee_landing_page/Jollibee_landing_page.jpg'
import jollibee_lp1 from '../../../../../../../../../Assets/images/projects_pics/react_js/jollibee_landing_page/Jollibee_landing_page1.jpg'
import jollibee_lp2 from '../../../../../../../../../Assets/images/projects_pics/react_js/jollibee_landing_page/Jollibee_landing_page2.jpg'
import jollibee_lp3 from '../../../../../../../../../Assets/images/projects_pics/react_js/jollibee_landing_page/Jollibee_landing_page3.jpg'
import jollibee_lp4 from '../../../../../../../../../Assets/images/projects_pics/react_js/jollibee_landing_page/Jollibee_landing_page4.jpg'
import jollibee_lp5 from '../../../../../../../../../Assets/images/projects_pics/react_js/jollibee_landing_page/Jollibee_landing_page5.jpg'
import jollibee_lp6 from '../../../../../../../../../Assets/images/projects_pics/react_js/jollibee_landing_page/Jollibee_landing_page6.jpg'
import jollibee_lp7 from '../../../../../../../../../Assets/images/projects_pics/react_js/jollibee_landing_page/Jollibee_landing_page7.jpg'
import jollibee_lp8 from '../../../../../../../../../Assets/images/projects_pics/react_js/jollibee_landing_page/Jollibee_landing_page8.jpg'
import jollibee_lp9 from '../../../../../../../../../Assets/images/projects_pics/react_js/jollibee_landing_page/Jollibee_landing_page9.jpg'
import jollibee_lp10 from '../../../../../../../../../Assets/images/projects_pics/react_js/jollibee_landing_page/Jollibee_landing_page10.jpg'

const Jollibee_lp = [
        jollibee_lp,
        jollibee_lp1,
        jollibee_lp2,
        jollibee_lp3,
        jollibee_lp4,
        jollibee_lp5,
        jollibee_lp6,
        jollibee_lp7,
        jollibee_lp8,
        jollibee_lp9,
        jollibee_lp10,
    ]


export default Jollibee_lp