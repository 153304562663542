import joodash from '../../../../../../../../../Assets/images/projects_pics/data_analytics/calculating_business_metrics/joodash.jpg';
import joodash1 from '../../../../../../../../../Assets/images/projects_pics/data_analytics/calculating_business_metrics/joodash1.jpg';
import joodash2 from '../../../../../../../../../Assets/images/projects_pics/data_analytics/calculating_business_metrics/joodash2.jpg';
import joodash3 from '../../../../../../../../../Assets/images/projects_pics/data_analytics/calculating_business_metrics/joodash3.jpg';
import joodash4 from '../../../../../../../../../Assets/images/projects_pics/data_analytics/calculating_business_metrics/joodash4.jpg';
import joodash5 from '../../../../../../../../../Assets/images/projects_pics/data_analytics/calculating_business_metrics/joodash5.jpg';
import joodash6 from '../../../../../../../../../Assets/images/projects_pics/data_analytics/calculating_business_metrics/joodash6.jpg';
import joodash7 from '../../../../../../../../../Assets/images/projects_pics/data_analytics/calculating_business_metrics/joodash7.jpg';

const Joodash_proj = [
    joodash,
    joodash1,
    joodash2,
    joodash3,
    joodash4,
    joodash5,
    joodash6,
    joodash7,
  ];
  
  

export default Joodash_proj
  