import bank_and_budget_app from '../../../../../../../../../Assets/images/projects_pics/react_js/bank_and_budget_app/Bank_and_budget_app.jpg';
import bank_and_budget_app1 from '../../../../../../../../../Assets/images/projects_pics/react_js/bank_and_budget_app/Bank_and_budget_app1.jpg';
import bank_and_budget_app2 from '../../../../../../../../../Assets/images/projects_pics/react_js/bank_and_budget_app/Bank_and_budget_app2.jpg';
import bank_and_budget_app3 from '../../../../../../../../../Assets/images/projects_pics/react_js/bank_and_budget_app/Bank_and_budget_app3.jpg';
import bank_and_budget_app4 from '../../../../../../../../../Assets/images/projects_pics/react_js/bank_and_budget_app/Bank_and_budget_app4.jpg';
import bank_and_budget_app5 from '../../../../../../../../../Assets/images/projects_pics/react_js/bank_and_budget_app/Bank_and_budget_app5.jpg';
import bank_and_budget_app6 from '../../../../../../../../../Assets/images/projects_pics/react_js/bank_and_budget_app/Bank_and_budget_app6.jpg';
import bank_and_budget_app7 from '../../../../../../../../../Assets/images/projects_pics/react_js/bank_and_budget_app/Bank_and_budget_app7.jpg';
import bank_and_budget_app8 from '../../../../../../../../../Assets/images/projects_pics/react_js/bank_and_budget_app/Bank_and_budget_app8.jpg';
import bank_and_budget_app9 from '../../../../../../../../../Assets/images/projects_pics/react_js/bank_and_budget_app/Bank_and_budget_app9.jpg';
import bank_and_budget_app10 from '../../../../../../../../../Assets/images/projects_pics/react_js/bank_and_budget_app/Bank_and_budget_app10.jpg';
import bank_and_budget_app11 from '../../../../../../../../../Assets/images/projects_pics/react_js/bank_and_budget_app/Bank_and_budget_app11.jpg';
import bank_and_budget_app12 from '../../../../../../../../../Assets/images/projects_pics/react_js/bank_and_budget_app/Bank_and_budget_app12.jpg';
import bank_and_budget_app13 from '../../../../../../../../../Assets/images/projects_pics/react_js/bank_and_budget_app/Bank_and_budget_app13.jpg';
import bank_and_budget_app14 from '../../../../../../../../../Assets/images/projects_pics/react_js/bank_and_budget_app/Bank_and_budget_app14.jpg';
import bank_and_budget_app15 from '../../../../../../../../../Assets/images/projects_pics/react_js/bank_and_budget_app/Bank_and_budget_app15.jpg';
import bank_and_budget_app16 from '../../../../../../../../../Assets/images/projects_pics/react_js/bank_and_budget_app/Bank_and_budget_app16.jpg';
import bank_and_budget_app17 from '../../../../../../../../../Assets/images/projects_pics/react_js/bank_and_budget_app/Bank_and_budget_app17.jpg';
import bank_and_budget_app18 from '../../../../../../../../../Assets/images/projects_pics/react_js/bank_and_budget_app/Bank_and_budget_app18.jpg';
import bank_and_budget_app19 from '../../../../../../../../../Assets/images/projects_pics/react_js/bank_and_budget_app/Bank_and_budget_app19.jpg';
import bank_and_budget_app20 from '../../../../../../../../../Assets/images/projects_pics/react_js/bank_and_budget_app/Bank_and_budget_app20.jpg';
import bank_and_budget_app21 from '../../../../../../../../../Assets/images/projects_pics/react_js/bank_and_budget_app/Bank_and_budget_app21.jpg';
import bank_and_budget_app22 from '../../../../../../../../../Assets/images/projects_pics/react_js/bank_and_budget_app/Bank_and_budget_app22.jpg';
import bank_and_budget_app23 from '../../../../../../../../../Assets/images/projects_pics/react_js/bank_and_budget_app/Bank_and_budget_app23.jpg';
import bank_and_budget_app24 from '../../../../../../../../../Assets/images/projects_pics/react_js/bank_and_budget_app/Bank_and_budget_app24.jpg';
import bank_and_budget_app25 from '../../../../../../../../../Assets/images/projects_pics/react_js/bank_and_budget_app/Bank_and_budget_app25.jpg';
import bank_and_budget_app26 from '../../../../../../../../../Assets/images/projects_pics/react_js/bank_and_budget_app/Bank_and_budget_app26.jpg';
import bank_and_budget_app27 from '../../../../../../../../../Assets/images/projects_pics/react_js/bank_and_budget_app/Bank_and_budget_app27.jpg';
import bank_and_budget_app28 from '../../../../../../../../../Assets/images/projects_pics/react_js/bank_and_budget_app/Bank_and_budget_app28.jpg';
import bank_and_budget_app29 from '../../../../../../../../../Assets/images/projects_pics/react_js/bank_and_budget_app/Bank_and_budget_app29.jpg';
import bank_and_budget_app30 from '../../../../../../../../../Assets/images/projects_pics/react_js/bank_and_budget_app/Bank_and_budget_app30.jpg';
import bank_and_budget_app31 from '../../../../../../../../../Assets/images/projects_pics/react_js/bank_and_budget_app/Bank_and_budget_app31.jpg';
import bank_and_budget_app32 from '../../../../../../../../../Assets/images/projects_pics/react_js/bank_and_budget_app/Bank_and_budget_app32.jpg';
import bank_and_budget_app33 from '../../../../../../../../../Assets/images/projects_pics/react_js/bank_and_budget_app/Bank_and_budget_app33.jpg';
import bank_and_budget_app34 from '../../../../../../../../../Assets/images/projects_pics/react_js/bank_and_budget_app/Bank_and_budget_app34.jpg';
import bank_and_budget_app35 from '../../../../../../../../../Assets/images/projects_pics/react_js/bank_and_budget_app/Bank_and_budget_app35.jpg';
import bank_and_budget_app36 from '../../../../../../../../../Assets/images/projects_pics/react_js/bank_and_budget_app/Bank_and_budget_app36.jpg';
import bank_and_budget_app37 from '../../../../../../../../../Assets/images/projects_pics/react_js/bank_and_budget_app/Bank_and_budget_app37.jpg';

import bank_and_budget_app_vid from '../../../../../../../../../Assets/images/projects_pics/react_js/bank_and_budget_app/Bank_and_budget_app.mp4';
import bank_and_budget_app_vid1 from '../../../../../../../../../Assets/images/projects_pics/react_js/bank_and_budget_app/Bank_and_budget_app1.mp4';
import bank_and_budget_app_vid2 from '../../../../../../../../../Assets/images/projects_pics/react_js/bank_and_budget_app/Bank_and_budget_app2.mp4';
import bank_and_budget_app_vid3 from '../../../../../../../../../Assets/images/projects_pics/react_js/bank_and_budget_app/Bank_and_budget_app3.mp4';
import bank_and_budget_app_vid4 from '../../../../../../../../../Assets/images/projects_pics/react_js/bank_and_budget_app/Bank_and_budget_app4.mp4';

const Bank_and_budget_app = [
    bank_and_budget_app,
    bank_and_budget_app_vid,
    bank_and_budget_app_vid1,
    bank_and_budget_app_vid2,
    bank_and_budget_app_vid3,
    bank_and_budget_app_vid4,
    bank_and_budget_app1,
    bank_and_budget_app2,
    bank_and_budget_app3,
    bank_and_budget_app4,
    bank_and_budget_app5,
    bank_and_budget_app6,
    bank_and_budget_app7,
    bank_and_budget_app8,
    bank_and_budget_app9,
    bank_and_budget_app10,
    bank_and_budget_app11,
    bank_and_budget_app12,
    bank_and_budget_app13,
    bank_and_budget_app14,
    bank_and_budget_app15,
    bank_and_budget_app16,
    bank_and_budget_app17,
    bank_and_budget_app18,
    bank_and_budget_app19,
    bank_and_budget_app20,
    bank_and_budget_app21,
    bank_and_budget_app22,
    bank_and_budget_app23,
    bank_and_budget_app24,
    bank_and_budget_app25,
    bank_and_budget_app26,
    bank_and_budget_app27,
    bank_and_budget_app28,
    bank_and_budget_app29,
    bank_and_budget_app30,
    bank_and_budget_app31,
    bank_and_budget_app32,
    bank_and_budget_app33,
    bank_and_budget_app34,
    bank_and_budget_app35,
    bank_and_budget_app36,
    bank_and_budget_app37
];

export default Bank_and_budget_app;
