import lampaz_form from '../../../../../../../../../Assets/images/projects_pics/react_js/lampaz_form/Lampaz_form.jpg'
import lampaz_form1 from '../../../../../../../../../Assets/images/projects_pics/react_js/lampaz_form/Lampaz_form1.jpg'
import lampaz_form2 from '../../../../../../../../../Assets/images/projects_pics/react_js/lampaz_form/Lampaz_form2.jpg'
import lampaz_form3 from '../../../../../../../../../Assets/images/projects_pics/react_js/lampaz_form/Lampaz_form3.jpg'
import lampaz_form4 from '../../../../../../../../../Assets/images/projects_pics/react_js/lampaz_form/Lampaz_form4.jpg'
import lampaz_form5 from '../../../../../../../../../Assets/images/projects_pics/react_js/lampaz_form/Lampaz_form5.jpg'

const Lampaz_form = [
        lampaz_form,
        lampaz_form1,
        lampaz_form2,
        lampaz_form3,
        lampaz_form4,
        lampaz_form5
    ]


export default Lampaz_form