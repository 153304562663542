import slackord from '../../../../../../../../../Assets/images/projects_pics/react_js/slackord/Slackord_app.jpg'
import slackord1 from '../../../../../../../../../Assets/images/projects_pics/react_js/slackord/Slackord_app1.jpg'
import slackord2 from '../../../../../../../../../Assets/images/projects_pics/react_js/slackord/Slackord_app2.jpg'

const Slack_app = 
    [
        slackord,
        slackord1,
        slackord2
    ]


export default Slack_app