import my_profile from '../../../../../../../../../Assets/images/projects_pics/react_js/my_profile/My_profile.jpg'
import my_profile1 from '../../../../../../../../../Assets/images/projects_pics/react_js/my_profile/My_profile1.jpg'
import my_profile2 from '../../../../../../../../../Assets/images/projects_pics/react_js/my_profile/My_profile2.jpg'
import my_profile3 from '../../../../../../../../../Assets/images/projects_pics/react_js/my_profile/My_profile3.jpg'
import my_profile4 from '../../../../../../../../../Assets/images/projects_pics/react_js/my_profile/My_profile4.jpg'
import my_profile5 from '../../../../../../../../../Assets/images/projects_pics/react_js/my_profile/My_profile5.jpg'
import my_profile6 from '../../../../../../../../../Assets/images/projects_pics/react_js/my_profile/My_profile6.jpg'
import my_profile7 from '../../../../../../../../../Assets/images/projects_pics/react_js/my_profile/My_profile7.jpg'
import my_profile8 from '../../../../../../../../../Assets/images/projects_pics/react_js/my_profile/My_profile8.jpg'
import my_profile9 from '../../../../../../../../../Assets/images/projects_pics/react_js/my_profile/My_profile9.jpg'
import my_profile10 from '../../../../../../../../../Assets/images/projects_pics/react_js/my_profile/My_profile10.jpg'
import my_profile11 from '../../../../../../../../../Assets/images/projects_pics/react_js/my_profile/My_profile11.jpg'



const My_profile = [
        my_profile,
        my_profile1,
        my_profile2,
        my_profile3,
        my_profile4,
        my_profile5,
        my_profile6,
        my_profile7,
        my_profile8,
        my_profile9,
        my_profile10,
        my_profile11
    ]

export default My_profile