import yellevate from '../../../../../../../../../Assets/images/projects_pics/data_analytics/yellevate/yellevate.jpg';
import yellevate1 from '../../../../../../../../../Assets/images/projects_pics/data_analytics/yellevate/yellevate1.jpg';
import yellevate2 from '../../../../../../../../../Assets/images/projects_pics/data_analytics/yellevate/yellevate2.jpg';
import yellevate3 from '../../../../../../../../../Assets/images/projects_pics/data_analytics/yellevate/yellevate3.jpg';
import yellevate4 from '../../../../../../../../../Assets/images/projects_pics/data_analytics/yellevate/yellevate4.jpg';
import yellevate5 from '../../../../../../../../../Assets/images/projects_pics/data_analytics/yellevate/yellevate5.jpg';
import yellevate6 from '../../../../../../../../../Assets/images/projects_pics/data_analytics/yellevate/yellevate6.jpg';
import yellevate7 from '../../../../../../../../../Assets/images/projects_pics/data_analytics/yellevate/yellevate7.jpg';
import yellevate8 from '../../../../../../../../../Assets/images/projects_pics/data_analytics/yellevate/yellevate8.jpg';
import yellevate9 from '../../../../../../../../../Assets/images/projects_pics/data_analytics/yellevate/yellevate9.jpg';
import yellevate10 from '../../../../../../../../../Assets/images/projects_pics/data_analytics/yellevate/yellevate10.jpg';
import yellevate11 from '../../../../../../../../../Assets/images/projects_pics/data_analytics/yellevate/yellevate11.jpg';
import yellevate12 from '../../../../../../../../../Assets/images/projects_pics/data_analytics/yellevate/yellevate12.jpg';
import yellevate13 from '../../../../../../../../../Assets/images/projects_pics/data_analytics/yellevate/yellevate13.jpg';
import yellevate14 from '../../../../../../../../../Assets/images/projects_pics/data_analytics/yellevate/yellevate14.jpg';

import yellevate_vid from '../../../../../../../../../Assets/images/projects_pics/data_analytics/yellevate/yellevate.mp4';
import yellevate_vid1 from '../../../../../../../../../Assets/images/projects_pics/data_analytics/yellevate/yellevate1.mp4';
import yellevate_vid2 from '../../../../../../../../../Assets/images/projects_pics/data_analytics/yellevate/yellevate2.mp4';
import yellevate_vid3 from '../../../../../../../../../Assets/images/projects_pics/data_analytics/yellevate/yellevate3.mp4';
import yellevate_vid4 from '../../../../../../../../../Assets/images/projects_pics/data_analytics/yellevate/yellevate4.mp4';
import yellevate_vid5 from '../../../../../../../../../Assets/images/projects_pics/data_analytics/yellevate/yellevate5.mp4';
import yellevate_vid6 from '../../../../../../../../../Assets/images/projects_pics/data_analytics/yellevate/yellevate6.mp4';



const Yellevate = [
  yellevate,
  yellevate_vid,
  yellevate_vid1,
  yellevate_vid2,
  yellevate_vid3,
  yellevate_vid4,
  yellevate_vid5,
  yellevate_vid6,
  yellevate1,
  yellevate2,
  yellevate3,
  yellevate4,
  yellevate5,
  yellevate6,
  yellevate7,
  yellevate8,
  yellevate9,
  yellevate10,
  yellevate11,
  yellevate12,
  yellevate13,
  yellevate14,
];
  
export default Yellevate;
