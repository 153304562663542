import axios from "axios";

const SubmitInterest = {
    sendDetails: async (clientDetails, setIsLoading, setSuccessful) => {
        setIsLoading(true); 
        try {
            const bearer_token = process.env.REACT_APP_TOKEN_ONE;
            const url_post_one = process.env.REACT_APP_URL_POST_ONE;
            const token = bearer_token ? bearer_token : null;
            const firstResponse = await fetch("https://api.ipapi.com/api/check?access_key=de3ac659763f77cb270b553d441109e");
            const firstData = await firstResponse.json();     
            let cityAndCountry = 'Unknown';
            
            if (firstData.success === false) {
                const secondResponse = await fetch("https://api.ipstack.com/check?access_key=0cd51216afe1a57a89353b91f6cf2e7b");
                const secondData = await secondResponse.json();
                if (secondData && secondData.city && secondData.country_name) {
                    cityAndCountry = `${secondData.city}, ${secondData.country_name}`;
                }
            } else {
                if (firstData && firstData.city && firstData.country_name) {
                    cityAndCountry = `${firstData.city}, ${firstData.country_name}`;
                }
            }

            const clientDetailsWithLocation = {...clientDetails, country: cityAndCountry}          
            const headers = {
                Authorization: `Bearer ${token}`
            };

            const response = await axios.post(`${url_post_one}`, { client_detail: clientDetailsWithLocation }, { headers });
            const { data } = response;

            if (data) {
                setSuccessful(true)
                alert("Thank you for sending your interest! We will respond to you as soon as possible.");
            }
        } catch (error) {        
            setSuccessful(false);
            if (error.response && error.response.data) {
                const errorMessages = error.response.data;
                const formattedErrors = Object.entries(errorMessages)
                    .map(([field, messages]) => `${field}: ${messages.join(", ")}`)
                    .join("\n");
                
                alert(`Submission failed:\n${formattedErrors}`);
            } else {
                alert("Please submit again. Unexpected error occurred.");
            }
        } finally {
            setIsLoading(false);
        }
    }
}

export default SubmitInterest