import olist_trading from '../../../../../../../../../Assets/images/projects_pics/data_analytics/pbi_final_task/olis_trading.jpg';
import olist_trading1 from '../../../../../../../../../Assets/images/projects_pics/data_analytics/pbi_final_task/olis_trading1.jpg';
import olist_trading2 from '../../../../../../../../../Assets/images/projects_pics/data_analytics/pbi_final_task/olis_trading2.jpg';
import olist_trading3 from '../../../../../../../../../Assets/images/projects_pics/data_analytics/pbi_final_task/olis_trading3.jpg';
import olist_trading4 from '../../../../../../../../../Assets/images/projects_pics/data_analytics/pbi_final_task/olis_trading4.jpg';
import olist_trading5 from '../../../../../../../../../Assets/images/projects_pics/data_analytics/pbi_final_task/olis_trading5.jpg';
import olist_trading6 from '../../../../../../../../../Assets/images/projects_pics/data_analytics/pbi_final_task/olis_trading6.jpg';
import olist_trading7 from '../../../../../../../../../Assets/images/projects_pics/data_analytics/pbi_final_task/olis_trading7.jpg';
import olist_trading8 from '../../../../../../../../../Assets/images/projects_pics/data_analytics/pbi_final_task/olis_trading8.jpg';
import olist_trading9 from '../../../../../../../../../Assets/images/projects_pics/data_analytics/pbi_final_task/olis_trading9.jpg';
import olist_trading10 from '../../../../../../../../../Assets/images/projects_pics/data_analytics/pbi_final_task/olis_trading10.jpg';
import olist_trading11 from '../../../../../../../../../Assets/images/projects_pics/data_analytics/pbi_final_task/olis_trading11.jpg';
import olist_trading12 from '../../../../../../../../../Assets/images/projects_pics/data_analytics/pbi_final_task/olis_trading12.jpg';

const Olist_trading = [
  olist_trading,
  olist_trading1,
  olist_trading2,
  olist_trading3,
  olist_trading4,
  olist_trading5,
  olist_trading6,
  olist_trading7,
  olist_trading8,
  olist_trading9,
  olist_trading10,
  olist_trading11,
  olist_trading12
];

export default Olist_trading