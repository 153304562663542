import journal_app from '../../../../../../../../../Assets/images/projects_pics/ruby_rails/journal_app/journal_app.jpg'
import journal_app1 from '../../../../../../../../../Assets/images/projects_pics/ruby_rails/journal_app/journal_app1.jpg'
import journal_app2 from '../../../../../../../../../Assets/images/projects_pics/ruby_rails/journal_app/journal_app2.jpg'
import journal_app3 from '../../../../../../../../../Assets/images/projects_pics/ruby_rails/journal_app/journal_app3.jpg'
import journal_app4 from '../../../../../../../../../Assets/images/projects_pics/ruby_rails/journal_app/journal_app4.jpg'
import journal_app5 from '../../../../../../../../../Assets/images/projects_pics/ruby_rails/journal_app/journal_app5.jpg'
import journal_app6 from '../../../../../../../../../Assets/images/projects_pics/ruby_rails/journal_app/journal_app6.jpg'
import journal_app7 from '../../../../../../../../../Assets/images/projects_pics/ruby_rails/journal_app/journal_app7.jpg'
import journal_app8 from '../../../../../../../../../Assets/images/projects_pics/ruby_rails/journal_app/journal_app8.jpg'
import journal_app9 from '../../../../../../../../../Assets/images/projects_pics/ruby_rails/journal_app/journal_app9.jpg'
import journal_app10 from '../../../../../../../../../Assets/images/projects_pics/ruby_rails/journal_app/journal_app10.jpg'
import journal_app11 from '../../../../../../../../../Assets/images/projects_pics/ruby_rails/journal_app/journal_app11.jpg'
import journal_app12 from '../../../../../../../../../Assets/images/projects_pics/ruby_rails/journal_app/journal_app12.jpg'
import journal_app13 from '../../../../../../../../../Assets/images/projects_pics/ruby_rails/journal_app/journal_app13.jpg'
import journal_app14 from '../../../../../../../../../Assets/images/projects_pics/ruby_rails/journal_app/journal_app14.jpg'
import journal_app15 from '../../../../../../../../../Assets/images/projects_pics/ruby_rails/journal_app/journal_app15.jpg'
import journal_app1_vid from '../../../../../../../../../Assets/images/projects_pics/ruby_rails/journal_app/journal_app.mp4'
import journal_app2_vid from '../../../../../../../../../Assets/images/projects_pics/ruby_rails/journal_app/journal_app1.mp4'
import journal_app3_vid from '../../../../../../../../../Assets/images/projects_pics/ruby_rails/journal_app/journal_app2.mp4'
import journal_app4_vid from '../../../../../../../../../Assets/images/projects_pics/ruby_rails/journal_app/journal_app3.mp4'
import journal_app5_vid from '../../../../../../../../../Assets/images/projects_pics/ruby_rails/journal_app/journal_app4.mp4'

const Journal_app = [
    journal_app,
    journal_app1,
    journal_app2,
    journal_app3,
    journal_app4,
    journal_app5,
    journal_app6,
    journal_app7,
    journal_app8,
    journal_app9,
    journal_app10,
    journal_app11,
    journal_app12,
    journal_app13,
    journal_app14,
    journal_app15,
    journal_app1_vid,
    journal_app2_vid,
    journal_app3_vid,
    journal_app4_vid,
    journal_app5_vid,
]

export default Journal_app;