import youtube_db from '../../../../../../../../../Assets/images/projects_pics/data_analytics/youtube_dashboard/youtube_db.jpg';
import youtube_db1 from '../../../../../../../../../Assets/images/projects_pics/data_analytics/youtube_dashboard/youtube_db1.jpg';
import youtube_db2 from '../../../../../../../../../Assets/images/projects_pics/data_analytics/youtube_dashboard/youtube_db2.jpg';
import youtube_db3 from '../../../../../../../../../Assets/images/projects_pics/data_analytics/youtube_dashboard/youtube_db3.jpg';
import youtube_db4 from '../../../../../../../../../Assets/images/projects_pics/data_analytics/youtube_dashboard/youtube_db4.jpg';
import youtube_db5 from '../../../../../../../../../Assets/images/projects_pics/data_analytics/youtube_dashboard/youtube_db5.jpg';
import youtube_db6 from '../../../../../../../../../Assets/images/projects_pics/data_analytics/youtube_dashboard/youtube_db6.jpg';
import youtube_db7 from '../../../../../../../../../Assets/images/projects_pics/data_analytics/youtube_dashboard/youtube_db7.jpg';
import youtube_db8 from '../../../../../../../../../Assets/images/projects_pics/data_analytics/youtube_dashboard/youtube_db8.jpg';
import youtube_db9 from '../../../../../../../../../Assets/images/projects_pics/data_analytics/youtube_dashboard/youtube_db9.jpg';

const Youtube_dashboard = [
  youtube_db,
  youtube_db1,
  youtube_db2,
  youtube_db3,
  youtube_db4,
  youtube_db5,
  youtube_db6,
  youtube_db7,
  youtube_db8,
  youtube_db9
];

export default Youtube_dashboard