import './MyProfilePg.css'
import { useState, useEffect } from 'react'
import TopNav from './components/TopNav/TopNav'
import HomeSec from './components/HomeSec/HomeSec'
import AbtmeSec from './components/AbtmeSec/AbtmeSec'
import SkillsSec from './components/SkillsSec/SkillsSec'
import PrtfolioSec from './components/PrtfolioSec/PrtfolioSec'
import WbDetails from './components/PrtfolioSec/components/projDetails/projDetails'
import CntactSec from './components/CntactSec/CntactSec'
import ButtomNav from './components/BottomNav/ButtomNav'
import LftSdeButton from './components/LftSdeBut/LftSdeButton'
// import RegisterViewer from '../../Services/RegisterViewer'
import 'bootstrap-icons/font/bootstrap-icons.css';

export default function MyProfilePg() {
  const [lptpView, setLptpView] = useState(true)
  const [shwVwWbDtls, setShwVwWbDtls] = useState(false)
  const [prjctName, setPrjctName] =  useState([])
  const [isLoading, setIsLoading] = useState(false)
  // const [viewerEntry, setViewerEntry] = useState(null);

  // useEffect(()=>{
  //   RegisterViewer.viewerRegChecker(setViewerEntry);
  // },[])

  // useEffect(() => {
  //   const fetchVisitorData = async () => {
  //     try {
  //       const response = await fetch("https://api.ipgeolocation.io/ipgeo?apiKey=c579c67bf5334f79968d2f5a051b43d6");
  
  //       if (!response.ok) throw new Error('Failed to fetch visitor data');
  
  //       const data = await response.json();
  //       const viewersIp = data.ip || null; 
  //       const location = data.city && data.country_name ? `${data.city}, ${data.country_name}` : 'Unknown';
  
  //       if (!viewersIp) {
  //         return;
  //       }
  //       const existingViewer = viewerEntry?.find((viewer) => viewer.ip_address === viewersIp);
  
  //       const recordNewVisitor = () => {
  //         const newVisitor = { location, ip_address: viewersIp };
  //         RegisterViewer.sendIpAndLocOnly(newVisitor);
  //         setViewerEntry((prev) => [...(prev || []), newVisitor]);
  
  //         localStorage.setItem('lastVisitor', JSON.stringify(newVisitor));
  //       };
  
  //       const lastVisitor = JSON.parse(localStorage.getItem('lastVisitor'));
  //       if (lastVisitor?.ip_address === viewersIp) {
  //         return;
  //       }
  
  //       if (existingViewer) {
  //         const lastVisitTime = new Date(existingViewer.created_at).getTime();
  //         const currentTime = Date.now();
  //         const hoursDifference = (currentTime - lastVisitTime) / (1000 * 60 * 60);
  
  //         if (hoursDifference > 24) {
  //           recordNewVisitor();
  //         }
  //       } else {
  //         recordNewVisitor();
  //       }
  //     } catch (error) {
  //       return
  //     }
  //   };
  
  //   fetchVisitorData();
  // }, [viewerEntry]);
  
  useEffect(() => {
    const handleResize = () => setLptpView(window.innerWidth > 950);
    handleResize();
    window.addEventListener('resize', handleResize);
    return () =>  window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className='myProfile-page'>
      <TopNav/>
      <HomeSec/>
      <AbtmeSec/>
      <SkillsSec/>
      <PrtfolioSec
        setShwVwWbDtls={setShwVwWbDtls}
        setPrjctName={setPrjctName}
      />
      {shwVwWbDtls && 
        <WbDetails
        setShwVwWbDtls={setShwVwWbDtls}
        setPrjctName={setPrjctName}
        prjctName={prjctName}
        />
      }
      <CntactSec
       setIsLoading={setIsLoading}
      />
      <ButtomNav/>

      {lptpView && 
        <LftSdeButton/>
      }

      {isLoading &&
        <div className='submitDelay-container'>
          <div class="spinner-border m-5" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
            <h4>Thank you! Please don't refresh as we process your details.</h4>
        </div>
      }
    </div>
  )
}
